import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { MouseParallax, ScrollParallax } from "react-just-parallax";
import GpsFixedIcon from "@mui/icons-material/GpsFixed";
import LanguageIcon from "@mui/icons-material/Language";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
var Nexteam = require("../media/NEXTEAM_banner.png");
export default function Section6M() {
	return (
		<Box width="100vw" height="80vh">
			<ScrollParallax strength={0.05}>
				<Typography
					width="100vw"
					variant="h5"
					mt="10vh"
					textAlign="center"
					color="rgba(255,255,255,0.1)">
					Implementações Futuras
				</Typography>
				<Grid
					container
					width="100vw"
					direction="column"
					justifyContent="space-around"
					alignItems="center"
					spacing={2}>
					<Grid item>
						<ScrollParallax>
							<div class="glass-btn-m amber-btn">
								<Grid
									container
									direction="column"
									justifyContent={"space-around"}
									alignItems="center">
									<Grid item>
										<GpsFixedIcon className="svg_icons" />
									</Grid>
									<Grid item>
										<Typography
											p="2vw"
											fontSize={30}
											textAlign={"center"}
											textTransform={"capitalize"}>
											<b>Tracking live</b>
										</Typography>
									</Grid>
									<Grid item>
										<Typography width="50vw" textAlign={"center"}>
											Verifique onde os seus técnicos estão em tempo real
										</Typography>
									</Grid>
								</Grid>
							</div>
						</ScrollParallax>
					</Grid>
					<Grid item>
						<ScrollParallax>
							<div class="glass-btn-m amber-btn">
								<Grid
									container
									direction="column"
									justifyContent={"space-around"}
									alignItems="center">
									<Grid item>
										<LanguageIcon className="svg_icons" />
									</Grid>
									<Grid item>
										<Typography
											p="2vw"
											fontSize={30}
											textAlign={"center"}
											textTransform={"capitalize"}>
											<b>Agendamentos Online</b>
										</Typography>
									</Grid>
									<Grid item>
										<Typography width="50vw" textAlign={"center"}>
											Os Clientes vão poder agendar ordens de serviço no seu
											establecimento.
										</Typography>
									</Grid>
								</Grid>
							</div>
						</ScrollParallax>
					</Grid>
					<Grid item>
						<ScrollParallax>
							<div class="glass-btn-m amber-btn">
								<Grid
									container
									direction="column"
									justifyContent={"space-around"}
									alignItems="center">
									<Grid item>
										<LightbulbIcon className="svg_icons" />
									</Grid>
									<Grid item>
										<Typography
											p="2vw"
											fontSize={30}
											textAlign={"center"}
											textTransform={"capitalize"}>
											<b>AI feedback</b>
										</Typography>
									</Grid>
									<Grid item>
										<Typography width="50vw" textAlign={"center"}>
											acreditamos que a inteligência artificial vai revolucionar
											a forma como os dados são analizados.
										</Typography>
									</Grid>
								</Grid>
							</div>
						</ScrollParallax>
					</Grid>
				</Grid>
				<Grid
					container
					direction="column"
					width="100vw"
					alignContent="center"
					justifyContent={"center"}>
					<Grid item>
						<Typography variant="h3" color="gray" textAlign="center">
							Desenvolvido por
						</Typography>
					</Grid>
					<Grid item alignSelf={"center"} width="60vw" mt={5} pb={10}>
						<img src={Nexteam} width={"100%"} />
					</Grid>
				</Grid>
			</ScrollParallax>
		</Box>
	);
}

import { Box, Grid, Paper, Typography } from "@mui/material";
import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { MouseParallax, ScrollParallax } from "react-just-parallax";
import Zoom from "react-reveal/Zoom";
var img = require("../media/pcmock.png");
var logo = require("../media/logo.png");
const Section3M = () => {
	return (
		<Grid
			container
			width="100vw"
			alignContent={"center"}
			direction="column"
			justifyContent={"center"}
			mt={"10vh"}>
			<Grid item>
				<ScrollParallax strength={0.05}>
					<Grid
						width="80vw"
						container
						direction="column"
						alignContent={"center"}
						justifyContent="center">
						<Grid item>
							<Typography variant="h3" color="white">
								{" "}
								Insights sobre o teu <b>Negócio</b>
							</Typography>
						</Grid>
						<Grid item>
							<Typography variant="h6" color="white" mt={2}>
								{" "}
								Vizualiza o numeros do teu Negócio facilmente com dashboards com
								os indicativos mais importantes!
							</Typography>
						</Grid>
					</Grid>
				</ScrollParallax>
			</Grid>

			<Grid item>
				<Grid
					width="40vw"
					container
					direction="column"
					alignContent={"center"}
					justifyContent="center">
					<Grid item>
						<Box sx={{ borderRadius: "30px" }} width="60vw" height="90vh">
							<Grid
								container
								width="100vw"
								alignContent={"center"}
								direction="column"
								justifyContent="center"
								mt={5}>
								<Grid item mt={2}>
									<Box
										sx={{ borderRadius: "20px", background: "#1E1C26" }}
										width="60vw"
										height="30vh"
										className="shadow">
										<Grid
											container
											width="60vw"
											height="30vh"
											direction="column"
											justifyContent="center"
											alignItems="center"
											color="white">
											<Grid item>
												<Typography variant="h5">
													<b>Téncica</b>
												</Typography>
											</Grid>
											<Grid item>
												<Typography variant="h6">5 serviços</Typography>
											</Grid>
											<Grid item>
												<Typography variant="subtitle1" color="green">
													+ 20%
												</Typography>
											</Grid>
										</Grid>
									</Box>
								</Grid>
							</Grid>
						</Box>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};
export default Section3M;

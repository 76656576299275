import { Grid, Typography } from "@mui/material";
import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import Zoom from "react-reveal/Zoom";
var img = require("../media/servicesPanel.png");
var logo = require("../media/logo.png");

const PresentationM = () => {
	return (
		<Grid
			container
			width="100vw"
			height="100vh"
			alignContent={"center"}
			direction="row"
			justifyContent={"center"}>
			<Grid item>
				<Zoom>
					<Grid item>
						<img src={logo} height={"80"} />
					</Grid>
				</Zoom>
			</Grid>
		</Grid>
	);
};
export default PresentationM;

import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import Navbar, { MyComponent } from "./components/navbar";
import Presentation from "./components/section1";
import { Divider, Grid } from "@mui/material";
import Section2 from "./components/section2";
import "@fontsource/mukta-mahee";
import Section3 from "./components/section3";
import Section4 from "./components/section4";
import Section5 from "./components/section5";
import MetaTags from "react-meta-tags";
import { isMobile } from "react-device-detect";
import Section6 from "./components/section6";
import PresentationM from "./componentsMobile/section1";
import Section2M from "./componentsMobile/section2";
import Section3M from "./componentsMobile/section3";
import Section4M from "./componentsMobile/section4";
import Section5M from "./componentsMobile/section5";
import Section6M from "./componentsMobile/section6";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<React.StrictMode>
		<MetaTags>
			<title>MyTeck</title>
			<meta
				id="meta-description"
				name="description"
				content="simple and easy to use CMMS built with feedback from experienced technicians and HR's  "
			/>
			<meta id="og-title" property="og:title" content="MyTeck" />
			<meta id="og-image" property="og:image" content="path/to/image.jpg" />
		</MetaTags>
		{!isMobile ? (
			<Grid
				container
				direction="column"
				sx={{
					width: "100vw",

					fontFamily: "Mukta Mahee",
				}}>
				<Presentation />

				<Section2 />

				<Section3 />

				<Section4 />
				<Section5 />
				<Section6 />
			</Grid>
		) : (
			<Grid
				container
				direction="column"
				sx={{
					width: "100vw",

					fontFamily: "Mukta Mahee",
				}}>
				<PresentationM />

				<Section2M />

				<Section3M />

				<Section4M />
				<Section5M />
				<Section6M />
			</Grid>
		)}
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import { Grid, Typography } from "@mui/material";
import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import Zoom from "react-reveal/Zoom";
var img = require("../media/servicesPanel.png");
var logo = require("../media/logo.png");

const Presentation = () => {
	return (
		<Grid container width="100vw" alignContent={"center"} direction="row">
			<Grid item>
				<Grid
					width="100vw"
					height="100vh"
					container
					direction="column"
					alignContent={"center"}
					justifyContent="center">
					<Grid item>
						<Zoom>
							<img src={logo} height={"100px"} />
						</Zoom>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};
export default Presentation;

import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { MouseParallax, ScrollParallax } from "react-just-parallax";
var img = require("../media/servicesPanel.png");
export default function Section5() {
	return (
		<Box width="100vw" height="130vh" backgroundColor="#5b3f91">
			<ScrollParallax strength={0.15} zIndex={0}>
				<Typography
					variant="h1"
					mt="10vh"
					textAlign="center"
					fontSize="250px"
					color="rgba(255,255,255,0.1)">
					Versão Beta
				</Typography>

				<Grid
					container
					width="100vw"
					height="50vh"
					direction="row"
					justifyContent={"space-around"}
					alignContent="center">
					<Grid item>
						<Grid
							container
							direction="column"
							justifyContent={"space-around"}
							alignContent="center">
							<Grid item>
								<Typography variant="h3" color="white">
									Contacte-nos para saber mais
								</Typography>
							</Grid>
							<Grid item>
								<Typography variant="h6" color="#1E1C26">
									Queremos crescer com o seu feedback!
								</Typography>
							</Grid>
							<Grid item>
								<Typography variant="h1" color="#1E1C26">
									<b>info@nexteam.pt</b>
								</Typography>
							</Grid>
						</Grid>
					</Grid>

					<Grid item width="40vw" className="float">
						<img src={img} width="100%"></img>
					</Grid>
				</Grid>
			</ScrollParallax>
		</Box>
	);
}

import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import PersonIcon from "@mui/icons-material/Person";
import EuroIcon from "@mui/icons-material/Euro";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import { MouseParallax, ScrollParallax } from "react-just-parallax";

const Section2M = () => {
	return (
		<Grid
			container
			width="100vw"
			direction="column"
			justifyContent="space-around"
			alignItems="center">
			<Grid item>
				<div class="glass-btn-m amber-btn">
					<Grid
						container
						direction="column"
						justifyContent={"space-around"}
						alignItems="center">
						<Grid item>
							<PersonIcon className="svg_icons" />
						</Grid>
						<Grid item>
							<Typography
								p="2vw"
								fontSize={30}
								textAlign={"center"}
								textTransform={"capitalize"}>
								<b>Gestão de Recursos Humanos</b>
							</Typography>
						</Grid>
						<Grid item>
							<Typography width="50vw" textAlign={"center"}>
								Simplifique a Gestão dos seus recursos Humanos
							</Typography>
						</Grid>
					</Grid>
				</div>
			</Grid>
			<Grid item>
				<div class="glass-btn-m amber-btn">
					<Grid
						container
						direction="column"
						justifyContent={"space-around"}
						alignItems="center">
						<Grid item>
							<EuroIcon className="svg_icons" />
						</Grid>
						<Grid item>
							<Typography
								p="2vw"
								fontSize={30}
								textAlign={"center"}
								textTransform={"capitalize"}>
								<b>Gestão de Fluxo de caixa</b>
							</Typography>
						</Grid>
						<Grid item>
							<Typography width="50vw" textAlign={"center"}>
								Simplifique a Gestão do seu fluxo de caixa, nós tratamos disso!
							</Typography>
						</Grid>
					</Grid>
				</div>
			</Grid>
			<Grid item>
				<div class="glass-btn-m amber-btn">
					<Grid
						container
						direction="column"
						justifyContent={"space-around"}
						alignItems="center">
						<Grid item>
							<EmojiEventsIcon className="svg_icons" />
						</Grid>
						<Grid item>
							<Typography
								p="2vw"
								fontSize={30}
								textAlign={"center"}
								textTransform={"capitalize"}>
								<b>Atribuição de prémios</b>
							</Typography>
						</Grid>
						<Grid item>
							<Typography width="50vw" textAlign={"center"}>
								A recompensa é uma forma eficaz de reconhecer o valor ao seu
								colaborador
							</Typography>
						</Grid>
					</Grid>
				</div>
			</Grid>
		</Grid>
	);
};
export default Section2M;

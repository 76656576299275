import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import Zoom from "react-reveal/Zoom";
import { MouseParallax, ScrollParallax } from "react-just-parallax";
var img = require("../media/pcmock2.png");
var logo = require("../media/logo.png");

const Section4M = () => {
	return (
		<Grid
			container
			width="100vw"
			alignContent={"center"}
			direction="column"
			justifyContent={"center"}
			pb={10}>
			<Grid item>
				<ScrollParallax strength={0.05}>
					<Grid
						width="34vw"
						container
						direction="column"
						alignContent={"center"}
						justifyContent="center">
						<Grid item>
							<Typography variant="h3" color="white">
								{" "}
								Relatórios detalhados
								<br></br> <b>força técnica</b>
							</Typography>
						</Grid>
						<Grid item>
							<Typography
								variant="h6"
								width="70vw"
								textAlign={"-moz-initial"}
								color="white"
								mt={2}>
								{" "}
								Vizualiza em detalhe todos os serviços efetuados pelos teus
								técnicos e a média de tempo dispensados para os mesmos! <br />
								Se aplicável, veja tambem qual o valor acumulado em prémios do
								mesmo
							</Typography>
						</Grid>
					</Grid>
				</ScrollParallax>
			</Grid>
			<Grid item>
				<Grid
					width="40vw"
					container
					direction="column"
					alignContent={"center"}
					justifyContent="center">
					<Grid item mt={2}>
						<Box
							sx={{ borderRadius: "20px", background: "#1E1C26" }}
							width="80vw"
							height="30vh"
							className="shadow">
							<Grid
								container
								direction="column"
								alignItems="center"
								width="80vw"
								height="30vh"
								mt={5}>
								<Grid item mt={2}>
									<Typography variant="h3" color="#5B8930" gutterBottom>
										$
									</Typography>
								</Grid>
								<Grid item>
									<Typography variant="h6" color="gray" gutterBottom>
										Total Serviços Realizados
									</Typography>
								</Grid>
								<Grid item>
									<Grid
										container
										direction="column"
										justifyContent="center"
										alignItems="center">
										<Typography variant="h5" color="white">
											{" "}
											100 €
										</Typography>
									</Grid>
								</Grid>
								<Grid item>
									<Typography variant="h6" color="white" gutterBottom>
										30
									</Typography>
								</Grid>
							</Grid>
						</Box>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};
export default Section4M;
